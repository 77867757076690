<footer class="footer">
	<i nz-icon>
		<svg xmlns="http://www.w3.org/2000/svg" width="82" height="18" viewBox="0 0 82 18" fill="none">
			<g clip-path="url(#clip0_2583_34705)">
				<path
					d="M4.03723 10.9684C4.53727 10.9684 4.94263 10.5604 4.94263 10.0572C4.94263 9.55395 4.53727 9.146 4.03723 9.146C3.5372 9.146 3.13184 9.55395 3.13184 10.0572C3.13184 10.5604 3.5372 10.9684 4.03723 10.9684Z"
					fill="#003555"
				/>
				<path
					d="M14.1388 10.9684C14.6388 10.9684 15.0442 10.5604 15.0442 10.0572C15.0442 9.55395 14.6388 9.146 14.1388 9.146C13.6388 9.146 13.2334 9.55395 13.2334 10.0572C13.2334 10.5604 13.6388 10.9684 14.1388 10.9684Z"
					fill="#003555"
				/>
				<path
					d="M9.35168 9.05775C9.85172 9.05775 10.2571 8.64979 10.2571 8.14655C10.2571 7.64331 9.85172 7.23535 9.35168 7.23535C8.85165 7.23535 8.44629 7.64331 8.44629 8.14655C8.44629 8.64979 8.85165 9.05775 9.35168 9.05775Z"
					fill="#003555"
				/>
				<path
					d="M16.7109 7.32173C16.7834 7.41506 17.3078 8.07343 17.903 8.07343C17.9148 8.07343 17.9282 8.07343 17.94 8.07343C18.5504 8.04458 19.0309 7.31325 19.095 7.21144C19.0225 7.11642 18.4762 6.4309 17.8659 6.46144C17.2555 6.49029 16.775 7.22162 16.7109 7.32343V7.32173Z"
					fill="#003555"
				/>
				<path
					d="M7.23402 8.10743C7.34024 8.04973 8.09896 7.61874 8.16808 7.00788C8.2389 6.39702 7.59821 5.80313 7.50885 5.72168C7.40263 5.77937 6.64392 6.21037 6.57479 6.82123C6.50398 7.43209 7.14467 8.02598 7.23402 8.10743Z"
					fill="#003555"
				/>
				<path
					d="M8.07191 4.87842C8.17813 4.82073 8.93685 4.38973 9.00597 3.77887C9.07679 3.16802 8.4361 2.57412 8.34674 2.49268C8.24052 2.55037 7.48181 2.98136 7.41268 3.59222C7.34187 4.20308 7.98256 4.79697 8.07191 4.87842Z"
					fill="#003555"
				/>
				<path
					d="M6.11781 5.66749C6.22403 5.60979 6.98106 5.1788 7.05187 4.56794C7.12268 3.95708 6.48199 3.36319 6.39264 3.28174C6.28642 3.33943 5.52939 3.77043 5.45858 4.38129C5.38776 4.99215 6.02845 5.58604 6.11781 5.66749Z"
					fill="#003555"
				/>
				<path
					d="M4.23125 2.51283C4.10311 3.1135 4.68479 3.76678 4.76572 3.85672C4.877 3.80921 5.6728 3.45287 5.80094 2.85219C5.92908 2.24982 5.3474 1.59823 5.26647 1.5083C5.15519 1.55581 4.35939 1.91215 4.23294 2.51283H4.23125Z"
					fill="#003555"
				/>
				<path
					d="M2.84521 3.51904C2.78283 3.62255 2.34952 4.38273 2.59736 4.94438C2.84689 5.50603 3.69834 5.68929 3.81636 5.71305C3.87874 5.60954 4.31205 4.84936 4.0642 4.28771C3.81467 3.72606 2.96323 3.5428 2.84521 3.51904Z"
					fill="#003555"
				/>
				<path
					d="M4.4863 8.34344C4.4981 8.22296 4.56217 7.34909 4.09683 6.95034C3.63148 6.55158 2.7851 6.7569 2.66876 6.78744C2.65696 6.90792 2.59289 7.78178 3.05823 8.18054C3.52358 8.5793 4.36996 8.37398 4.4863 8.34344Z"
					fill="#003555"
				/>
				<path
					d="M0.775391 10.6546C0.792251 10.7751 0.937249 11.6388 1.48352 11.9137C2.02979 12.1886 2.80368 11.7864 2.9099 11.7287C2.89304 11.6083 2.74804 10.7446 2.20177 10.4697C1.6555 10.1948 0.88161 10.597 0.775391 10.6546Z"
					fill="#003555"
				/>
				<path
					d="M0.801937 7.62891C0.742926 7.73411 0.324792 8.50278 0.586126 9.05934C0.847459 9.6159 1.70227 9.78049 1.8203 9.80085C1.87931 9.69565 2.29744 8.92698 2.03611 8.37042C1.77477 7.81386 0.919958 7.64927 0.801937 7.62891Z"
					fill="#003555"
				/>
				<path
					d="M0.792346 5.70963C0.662522 6.31031 1.24083 6.96528 1.32176 7.05522C1.43304 7.0094 2.23053 6.65476 2.36035 6.05408C2.49017 5.45341 1.91187 4.79843 1.83094 4.7085C1.71966 4.75431 0.923856 5.10895 0.792346 5.70963Z"
					fill="#003555"
				/>
				<path
					d="M11.0414 6.57527C10.7497 7.11656 11.1257 7.90728 11.1796 8.01588C11.2993 8.0023 12.1626 7.88522 12.4526 7.34393C12.7426 6.80264 12.3683 6.01192 12.3143 5.90332C12.1946 5.91689 11.3314 6.03398 11.0414 6.57527Z"
					fill="#003555"
				/>
				<path
					d="M11.6588 4.04353C11.3688 4.58482 11.7431 5.37554 11.7971 5.48414C11.9168 5.47056 12.78 5.35348 13.07 4.81219C13.3617 4.2709 12.9857 3.48018 12.9318 3.37158C12.8121 3.38516 11.9488 3.50224 11.6588 4.04353Z"
					fill="#003555"
				/>
				<path
					d="M8.93554 5.31809C8.82089 5.92216 9.41438 6.56356 9.49868 6.6501C9.60827 6.60089 10.3973 6.22759 10.512 5.62352C10.6266 5.01944 10.0331 4.37804 9.94885 4.2915C9.83925 4.34071 9.05019 4.71402 8.93554 5.31809Z"
					fill="#003555"
				/>
				<path
					d="M13.6947 5.66902C13.403 6.21031 13.779 7.00103 13.833 7.10963C13.9527 7.09605 14.8159 6.97897 15.1059 6.43768C15.3959 5.89639 15.0216 5.10567 14.9677 4.99707C14.8479 5.01064 13.9847 5.12773 13.6947 5.66902Z"
					fill="#003555"
				/>
				<path
					d="M15.8375 5.20271C15.5475 5.744 15.9218 6.53472 15.9758 6.64332C16.0955 6.62974 16.9587 6.51266 17.2487 5.97137C17.5387 5.43008 17.1644 4.63936 17.1105 4.53076C16.9908 4.54434 16.1275 4.66142 15.8375 5.20271Z"
					fill="#003555"
				/>
				<path
					d="M6.94895 0.707643C6.42965 1.03004 6.36221 1.90391 6.35547 2.02438C6.46675 2.0719 7.27267 2.40448 7.79196 2.08208C8.31294 1.75968 8.3787 0.88581 8.38544 0.763638C8.27417 0.716127 7.46825 0.383548 6.94895 0.707643Z"
					fill="#003555"
				/>
				<path
					d="M15.3035 2.8607C14.71 2.71986 14.0474 3.2883 13.958 3.36975C14.0018 3.48174 14.339 4.29113 14.9342 4.43197C15.5294 4.5762 16.1903 4.00437 16.2797 3.92292C16.2358 3.81093 15.8986 3.00154 15.3035 2.8607Z"
					fill="#003555"
				/>
				<path
					d="M10.7312 2.16539C10.1377 2.02455 9.4751 2.59299 9.38574 2.67444C9.42958 2.78643 9.76678 3.59582 10.362 3.73666C10.9571 3.87919 11.618 3.30905 11.7074 3.22761C11.6636 3.11562 11.3264 2.30623 10.7312 2.16539Z"
					fill="#003555"
				/>
				<path
					d="M13.3445 1.06236C12.751 0.921524 12.0884 1.48996 11.999 1.57141C12.0429 1.6834 12.3801 2.49279 12.9752 2.63363C13.5704 2.77786 14.2313 2.20603 14.3207 2.12458C14.2768 2.01259 13.9396 1.2032 13.3445 1.06236Z"
					fill="#003555"
				/>
				<path
					d="M10.3331 0.00338975C9.7228 -0.0526057 9.14787 0.602372 9.07031 0.695697C9.12932 0.800901 9.57443 1.55429 10.1831 1.61199C10.2084 1.61368 10.2337 1.61538 10.259 1.61538C10.839 1.61538 11.3717 1.00791 11.4459 0.919679C11.3869 0.814476 10.9418 0.0610821 10.3331 0.00338975Z"
					fill="#003555"
				/>
				<path
					d="M17.0473 8.30749C16.4538 8.16496 15.7896 8.7334 15.7002 8.81315C15.744 8.92514 16.0796 9.73453 16.673 9.87876C17.2682 10.023 17.9308 9.45286 18.0202 9.3731C17.9763 9.26111 17.6408 8.45172 17.0473 8.30749Z"
					fill="#003555"
				/>
				<path
					d="M17.6737 10.9987C17.5861 10.9156 16.942 10.3251 16.3452 10.4472C15.7466 10.5711 15.3841 11.3669 15.3369 11.4789C15.4246 11.5621 16.0686 12.1526 16.6672 12.0304C17.2657 11.9065 17.6282 11.1107 17.6754 10.9987H17.6737Z"
					fill="#003555"
				/>
				<path
					d="M13.9526 11.5349C12.764 10.9546 11.3342 11.0326 11.3342 11.0326C13.3524 8.34655 16.2658 7.56601 16.2658 7.56601C8.08019 7.46929 7.02137 13.9478 6.95898 16.8714C8.441 12.3324 12.4925 11.635 13.9526 11.5349Z"
					fill="#003555"
				/>
				<path
					d="M8.67107 9.6959C5.3749 7.93289 4.73084 4.69873 4.73084 4.69873C4.00079 8.49964 6.97999 10.9685 6.97999 10.9685C4.42567 11.4555 2.40918 12.6874 2.40918 12.6874C2.40918 12.6874 4.3009 12.2768 6.73721 13.2847C7.08453 12.0375 7.67969 10.7751 8.67107 9.6959Z"
					fill="#003555"
				/>
				<path
					d="M10.3936 17.9999C9.86922 16.0994 9.8591 14.0615 9.8591 14.0615C9.3398 14.3347 8.15959 15.4173 7.49023 17.9999H10.3953H10.3936Z"
					fill="#003555"
				/>
				<path
					d="M32.9204 7.59814V13.851C32.9204 16.3623 31.2158 17.9997 28.7205 17.9997C26.2252 17.9997 24.4902 16.3623 24.4902 13.851V7.59814H26.4427V13.8357C26.4427 15.3951 27.3717 16.2842 28.7205 16.2842C30.0693 16.2842 30.9679 15.3951 30.9679 13.851V7.59814H32.9204Z"
					fill="#003555"
				/>
				<path
					d="M41.769 13.3689V17.891H39.879V13.6336C39.879 12.6817 39.3833 12.059 38.4543 12.059C37.2454 12.059 36.7025 13.15 36.7025 14.0714V17.8927H34.8125V10.5318L36.2692 10.469C36.4092 10.9832 36.5019 11.7484 36.5019 12.0759H36.5491C36.9217 11.1868 37.6653 10.4385 38.9972 10.4385C40.7945 10.4385 41.7707 11.6704 41.7707 13.3706L41.769 13.3689Z"
					fill="#003555"
				/>
				<path
					d="M44.5273 9.36121C43.8293 9.36121 43.2881 8.86234 43.2881 8.15985C43.2881 7.45737 43.831 6.9585 44.5273 6.9585C45.2236 6.9585 45.7665 7.47264 45.7665 8.15985C45.7665 8.84707 45.2085 9.36121 44.5273 9.36121ZM43.5663 10.5303L45.4563 10.4675V17.8912H43.5663V10.5303Z"
					fill="#003555"
				/>
				<path
					d="M55.823 17.8912H54.9698C54.9226 17.7351 54.8147 17.1582 54.7523 16.4404C54.1791 17.5009 53.0629 17.9845 51.6686 17.9845C48.9726 17.9845 47.1602 15.894 47.1602 12.7447C47.1602 9.59538 49.0822 7.50488 51.763 7.50488C54.6292 7.50488 55.6375 9.50036 55.6375 10.8273L54.3359 11.0462C54.3359 10.0637 53.6227 8.75375 51.7799 8.75375C49.937 8.75375 48.5882 10.1893 48.5882 12.7464C48.5882 15.1321 49.8274 16.739 51.8726 16.739C53.7323 16.739 54.6613 15.5547 54.6613 14.2125V13.9936H51.3921V12.8092H55.8246V17.8929L55.823 17.8912Z"
					fill="#003555"
				/>
				<path
					d="M64.484 17.8759C64.484 17.8759 64.1282 17.9693 63.7405 17.9693C62.9817 17.9693 62.4237 17.6112 62.4237 16.7526V16.7374H62.3933C62.1927 17.1124 61.5571 17.9845 59.8525 17.9845C58.2406 17.9845 57.374 17.1429 57.374 15.9415C57.374 14.3346 58.8931 13.6966 62.1151 13.6966H62.3326V13.2283C62.3326 12.2306 61.729 11.5909 60.5201 11.5909C59.4816 11.5909 58.8476 12.0117 58.8476 12.7752C58.8476 12.9466 58.878 13.04 58.9252 13.1502L57.6387 13.3691C57.6387 13.3691 57.546 13.0875 57.546 12.7294C57.546 11.3889 58.6925 10.437 60.5522 10.437C62.5349 10.437 63.6983 11.5128 63.6983 13.213V16.191C63.6983 16.5965 63.8062 16.7526 64.1485 16.7526C64.2412 16.7526 64.3491 16.7374 64.4115 16.7204L64.489 17.8742L64.484 17.8759ZM62.3309 15.3493V14.6469H62.1134C59.6181 14.6469 58.7667 15.0982 58.7667 15.9093C58.7667 16.4862 59.2 16.829 60.1307 16.829C61.5554 16.829 62.3309 16.0332 62.3309 15.3476V15.3493Z"
					fill="#003555"
				/>
				<path
					d="M72.7144 13.3846V17.8914H71.3352V13.3846C71.3352 12.3088 70.7316 11.5911 69.6307 11.5911C68.1116 11.5911 67.4304 12.9791 67.4304 14.0396V17.8914H66.0664V10.5306L67.0274 10.4831C67.1505 11.0447 67.2129 11.8711 67.2129 12.1986H67.2601C67.6479 11.2789 68.469 10.4355 69.8633 10.4355C71.6151 10.4355 72.7144 11.6199 72.7144 13.3829V13.3846Z"
					fill="#003555"
				/>
				<path
					d="M81.4996 17.8759C81.4996 17.8759 81.1439 17.9693 80.7561 17.9693C79.9974 17.9693 79.4393 17.6112 79.4393 16.7526V16.7374H79.4089C79.2083 17.1124 78.5727 17.9845 76.8681 17.9845C75.2563 17.9845 74.3896 17.1429 74.3896 15.9415C74.3896 14.3346 75.9088 13.6966 79.1307 13.6966H79.3482V13.2283C79.3482 12.2306 78.7447 11.5909 77.5358 11.5909C76.4972 11.5909 75.8632 12.0117 75.8632 12.7752C75.8632 12.9466 75.8936 13.04 75.9408 13.1502L74.6544 13.3691C74.6544 13.3691 74.5616 13.0875 74.5616 12.7294C74.5616 11.3889 75.7081 10.437 77.5678 10.437C79.5506 10.437 80.7139 11.5128 80.7139 13.213V16.191C80.7139 16.5965 80.8218 16.7526 81.1641 16.7526C81.2568 16.7526 81.3647 16.7374 81.4271 16.7204L81.5047 17.8742L81.4996 17.8759ZM79.3466 15.3493V14.6469H79.1291C76.6337 14.6469 75.7823 15.0982 75.7823 15.9093C75.7823 16.4862 76.2156 16.829 77.1463 16.829C78.571 16.829 79.3466 16.0332 79.3466 15.3476V15.3493Z"
					fill="#003555"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2583_34705">
					<rect width="81" height="18" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	</i>
	<a class="text-[13px] md:text-[15px]" (click)="onLinkClick($event, 'terms-and-conditions')">{{
		'auth.signup.termsAndConditionsAndPrivacyPolicy' | translate
	}}</a>
	<a href="mailto:info@unigana.com" class="text-[13px] md:text-[15px]">info&#64;unigana.com</a>
</footer>
